<template>
  <div class="main">
    <div class="main-item header p-5 bg-white">
      <div class="header-1 flex justify-between">
        <div class="header-1-1">我的应用</div>
        <a
          :href="`${$store.state.OP_DOMAIN}/doc`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="header-1-2 flex">
            <i>
              <img
                src="@/assets/my-application/cao-zuo-zhi-nan.png"
                alt=""
                width="13px"
                height="16px"
              />
            </i>
            <span class="ml-1.5">操作指南</span>
          </div>
        </a>
      </div>
      <div class="header-2 mt-5">
        <el-button class="l-btn-primary" @click="clickCreat">
          <span class="text-lg leading-none">+</span> 创建应用
        </el-button>
      </div>
    </div>
    <div class="mt-5">
      <el-table
        :data="tableData"
        header-row-class-name="custom-class-header-row"
        class="l-table"
        align="center"
      >
        <el-table-column prop="isvName" label="应用名称">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="clickAppName(scope.row.isvId)"
              class="l-link-primary"
              >{{ scope.row.isvName }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="appKey"
          label="应用ID"
          min-width="140px"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="状态" width="80px" align="center">
          <template slot-scope="scope">
            <div
              v-if="scope.row.frozenStatus === 1"
              class="flex items-center justify-center"
            >
              <span class="status-dot status-dot-1"></span>有效
            </div>
            <div
              v-else-if="scope.row.frozenStatus === 2"
              class="flex items-center justify-center"
            >
              <span class="status-dot status-dot-2"></span>禁用
            </div>
          </template>
        </el-table-column>
        <el-table-column label="开通服务">
          <template slot-scope="scope">
            <span>{{ transAbility(scope.row.ability) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                updateIsvStatusPost(scope.row.isvId, scope.row.frozenStatus)
              "
              class="l-link-primary"
            >
              <span v-if="scope.row.frozenStatus === 1">禁用</span>
              <span v-else-if="scope.row.frozenStatus === 2">启用</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
  <script>
import msgbox2ImgSrc from '@/assets/icon/msgbox2.png'

  export default {
    // el: '', // string | Element
    name: '', // string
    // parent: '', // Vue instance
    // functional: false, // boolean
    // delimiters: ['{{', '}}'], // Array<string>
    // comments: false,
    components: {},// Object
    // directives: {}, // Object
    // filters: {}, // Object
    // extends: '', // Object | Function
    // mixins: [], // Array<Object>
    // inheritAttrs: true, // boolean
    // model: '', // { prop?: string, event?: string }
    props: {}, // Array<string> | Object
    data () {
      return {
        tableData: [
          {
            isvName: '',
            appKey: '',
            createTime: '',
            // createTime: '上海市普陀区金沙江路 1518 弄',
            // createTime: '上海市普陀区金沙江路 1518 弄',
            // createTime: '上海市普陀区金沙江路 1518 弄',
          }
          ]
      }
    },
    computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
    // watch: {}, // { [key: string]: string | Function | Object | Array }
    beforeCreate () {},
    created () {
      this.getIsvListGet()
    },
    beforeMount () {},
    mounted () {},
    beforeUpdate () {},
    updated () {},
    activated () {},
    deactivated () {},
    beforeDestroy () {},
    destroyed () {},
    methods: {
transAbility(abilityList){
  if(!Array.isArray(abilityList) ){
return ''
  }

let newResList =abilityList.map(item=>{
  return item.name
})
let res = newResList.join('; ')
return res
},
      clickCreat(){
this.$router.push({name:'MyApplicationCreat'})
      },
      clickAppName(isvId){
this.$router.push({name:'MyApplicationDetail',query:{isvId}})
      },
      getIsvListGet(){
       return this.$http('get','/op/website/isv/getIsvList',{},{
          /* mock: 22 */
        }).then(
          res=>{
this.tableData =res
          }
        )
      },
      updateIsvStatusPost(isvId,frozenStatus){

      this.$confirm('确认修改状态', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        customClass: 'custom-class-msgbox',
        message: `
          <div class='flex'>
            <div><img src="${msgbox2ImgSrc}" width="22px" height="22px"></div>
            <div class="msgbox-right">
              <div>温馨提示</div>
              <div class="msgbox-right--msg">确认修改状态吗</div>
            </div>
          </div>`,
        dangerouslyUseHTMLString: true,
      }).then(() => {

return this.$http('post','/op/website/isv/updateIsvStatus',{
  isvId,
  frozenStatus
},{
  /* mock: 22 */
}).then(()=>{
      this.getIsvListGet()

})

      }).catch(() => {
        //
      })
      },
    } // { [key: string]: Function }
    // template: '', // render // string
    // renderError: '' // (createElement: () => VNode, error: Error) => VNode
  }
  </script>
  <style lang="scss" scoped>
.main-item {
  background: ffffff;
}
.header-1-1 {
  border-left-width: 2px;
  padding-left: 10px;
  border-color: #333333;
}
.header-1-2 {
  font-size: 14px;
}
</style>
  <style lang="scss">
body .custom-class-msgbox {
  width: 436px;
  height: 192px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 120, 255, 0.2);
  border-radius: 4px;
  padding: 32px 32px 24px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  .el-message-box__header {
    display: none;
  }

  .el-message-box__status.el-icon-warning {
    display: none;
  }
  .msgbox-right {
    margin-left: 16px;
  }

  .msgbox-right--msg {
    font-size: 14px;
    margin-top: 12px;
  }
  .el-message-box__btns {
    padding: 0;
    .el-button.el-button--primary {
      background: #0078ff;
      border-radius: 4px;
    }
    .el-button.el-button--primary {
      // background: #0078FF;
      border-radius: 4px;
    }
  }
  .el-message-box__content {
    padding: 0;
  }
}

.status-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}
.status-dot-1 {
  background: #52c41a;
}
.status-dot-2 {
  background: #8a8a8a;
}
</style>
import axios from 'axios'
// import router from '@/router/index.js'
import store from '@/store/index.js'


import Cookies from 'js-cookie'
let OP_COOKIE_PREFIX = process.env.VUE_APP_OP_COOKIE_PREFIX
let OP_DOMAIN = process.env.VUE_APP_OP_DOMAIN

// let getToken = function () {
//   return localStorage.getItem('accessToken')
// }

const { NODE_ENV: env } = process.env

let platform = 0

// let domain = 'http://39.102.147.121:10002'
// let domain = 'https://api.kezhitech.com/test'
// let domain = 'https://api.kezhitech.com'

let domain  = process.env.VUE_APP_API_BASE_URL

let mockDomain = 'http://123.57.183.59:3000/mock/' //有/

let gConfig = {
  isMock: true
}

export function http (method = 'GET', url, params = {}, config = {}) {
  let baseServer = domain
  if (gConfig.isMock && config.mock && env !== 'production') {
    baseServer = mockDomain + config.mock + ''
  }

//   if (gConfig.isMock && config.localMock && env !== 'production') {
//     baseServer = 'http://localhost:33333'
//   }

  const headers = {
    accessToken: store.state.accessToken
  }
  if (config.headers) {
    Object.assign(headers, config.headers)
  }

  let __abort = null
  const requestConfig = {
    // timeout: 15000,
    cancelToken: new axios.CancelToken(function executor (cancel) {
      __abort = cancel
    }),
    headers,
    method: method,
    url: ('' + url).startsWith('http') ? url : baseServer + url
  }

  if (config.platform) {
    params.platform = platform
  }

  if (String.prototype.toUpperCase.call(method) === 'GET') {
    requestConfig.params = params
  } else {
    requestConfig.data = params
  }
  let request = new Promise((resolve, reject) => {
    axios(requestConfig)
      .then(resp => {
        if (gConfig.isMock && config.mock && env !== 'production') {
          resp.data.code = 200
        }

        if (resp.data.code === 200) {
          resolve(resp.data.data)

          // Message({
          //   message: '成功',
          //   type: 'success',
          //   duration: 1 * 500
          // })
        } else {
        //   catchHttpCode(resp.data, config.unMsg)

          if (resp.data.code === 30000 || resp.data.code === 10003) {
            localStorage.setItem('accessToken', '')
            localStorage.setItem('username', '')
            this.$store.commit('SET_TOKEN', '')
            this.$store.commit('SET_USERNAME', '')

            Cookies.remove(`${OP_COOKIE_PREFIX}accessToken`, { domain: document.domain.split('.').slice(-2).join('.') })
            Cookies.remove(`${OP_COOKIE_PREFIX}username`, { domain: document.domain.split('.').slice(-2).join('.') })

            if (!config.unRedirectLogin) {
              window.location =`${OP_DOMAIN}/login?redirect=${window.location.href}`
            } else {
              return
            }
          }

          if (!config.unMsg) {

            // Message({
            //   message: resp.data.msg || 'Error',
            //   type: 'error',
            //   duration: 5 * 1000
            // })
          }

          console.log('reject')
          reject(resp.data)
        }
      })
      .catch(err => {
        console.log('err')
        // if (!config.unMsg) {
        //   Message({
        //     type: 'error',
        //     showClose: true,
        //     message: `${JSON.stringify(err)}`,
        //     duration: 0,
        //     customClass: 'custom-class-message--http-error'
        //   })
        // }
        reject(err)
      })
  })
  request.abort = __abort
  return request
}

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import Cookies from 'js-cookie'
// let OP_COOKIE_PREFIX = process.env.VUE_APP_OP_COOKIE_PREFIX
export default {
    beforeMount () {

    //     let accessToken  = Cookies.get(`${OP_COOKIE_PREFIX}accessToken`, { domain: document.domain.split('.').slice(-2).join('.') })
    //     let username =  Cookies.get(`${OP_COOKIE_PREFIX}username`, { domain: document.domain.split('.').slice(-2).join('.') })

    // this.$store.commit('SET_TOKEN', accessToken)
    // this.$store.commit('SET_USERNAME', username)
    this.$store.commit('INIT_USER')
    
  }
}
</script>

<style lang="scss">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>

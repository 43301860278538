<template>
  <div class="main">
    <div class="main-item header p-5 bg-white">
      <div class="header-1 flex justify-between">
        <div class="header-1-1">我的应用 / 应用详情</div>
      </div>
    </div>
    <div class="mt-22px p-5 pr-24 pb-8 bg-white">
      <div class="mt-22px">
        <div class="flex">
          <div class="item-title w-24">应用名称</div>
          <div class="item-value">{{ resData.isvName }}</div>
          <div class="ml-10">
            <el-button
              style="padding: 0"
              type="text"
              @click="clickEditName"
              class="l-link-primary"
              ><span class="flex"
                ><i
                  ><img
                    src="@/assets/my-application/edit.png"
                    width="13px"
                    height="14px" /></i
                ><span class="ml-1.5">修改</span></span
              ></el-button
            >
          </div>
        </div>
        <div class="flex mt-7">
          <div class="item-title w-24 whitespace-nowrap flex-shrink-0">
            应用描述
          </div>
          <div class="item-value">{{ resData.remark }}</div>
        </div>
        <div class="flex mt-7">
          <div class="item-title w-24">应用ID</div>
          <div class="item-value">{{ resData.appKey }}</div>
        </div>
        <div class="flex mt-7">
          <div class="item-title w-24">应用秘钥</div>
          <div class="item-value">{{ resData.appSecret }}</div>
        </div>
      </div>
      <div class="line h-px my-10"></div>
      <div class="flex mt-7">
        <div class="item-title w-24 flex-shrink-0">开通服务</div>
        <!-- <div class="item-value">{{resData.appSecret}}</div> -->
        <div
          v-if="resData.abilityList && resData.abilityList.length > 0"
          class="mr-10"
        >
          <span>{{
            resData.abilityList
              .map((item) => {
                return item.name;
              })
              .join("; ")
          }}</span>
        </div>
        <div>
          <el-button
            style="padding: 0"
            type="text"
            @click="clickChangeAbility"
            class="l-link-primary"
          >
            <template
              v-if="resData.abilityList && resData.abilityList.length > 0"
            >
              重新选择 >
            </template>
            <template v-else> 请选择AI能力 > </template>
          </el-button>
        </div>
      </div>
      <div class="mt-10 flex justify-center">
        <el-button class="l-btn-cancel" @click="clickCancle">返回</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      center
      width="460px"
      class="l-table-select-abi"
    >
      <div slot="title" class="select-abi-title">请选择AI能力</div>
      <div class="select-abi-title flex flex-col justify-center ">
        <!-- <el-checkbox-group v-model="checkList">
              <div v-for="item in abilityList" :key='item.abilityId' class="check-item">
    <el-checkbox   :label="item.abilityId">{{item.name}}</el-checkbox>
              </div>

           </el-checkbox-group> -->

        <!--  -->
        <el-checkbox-group
          v-model="checkListGroup[indexAbilityList]"
          v-for="(itemAbilityList, indexAbilityList) in abilityList"
          :key="itemAbilityList.abilityId"
          class="checkbox-group"
        >
          <div class="flex items-stretch">
            <div class="w-20 flex-none">{{ itemAbilityList.name }}</div>
            <div class="ml-8 flex-grow flex flex-wrap">
              <div
                v-for="itemAbility in itemAbilityList.children"
                :key="itemAbility.abilityId"
                class="item-check w-1/2"

              >
                <el-checkbox :label="itemAbility.abilityId">{{
                  itemAbility.name
                }}</el-checkbox>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex justify-center">
          <div>
            <el-button
              type="primary"
              @click="clickDilaogConfirm"
              class="l-btn-primary"
              >确 定</el-button
            >
          </div>
          <div class="ml-10">
            <el-button @click="dialogVisible = false" class="l-btn-default"
              >取 消</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
  export default {
    // el: '', // string | Element
    name: '', // string
    // parent: '', // Vue instance
    // functional: false, // boolean
    // delimiters: ['{{', '}}'], // Array<string>
    // comments: false,
    components: {},// Object
    // directives: {}, // Object
    // filters: {}, // Object
    // extends: '', // Object | Function
    // mixins: [], // Array<Object>
    // inheritAttrs: true, // boolean
    // model: '', // { prop?: string, event?: string }
    props: {}, // Array<string> | Object
    data () {
      return {
        dialogVisible:false,
        resData:{},
        isvId:'',
        abilityList:[],
        abilityFlatList:[],
        checkList:[],
        checkListGroup:[
          [],
          [],
          []
        ]
      }
    },
    computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
    // watch: {}, // { [key: string]: string | Function | Object | Array }
    beforeCreate () {},
    
    created () {
this.isvId = this.$route.query.isvId
this.getIsvInfoGet(this.isvId)
    },
    // beforeMount () {},
    // mounted () {},
    // beforeUpdate () {},
    // updated () {},
    // activated () {},
    // deactivated () {},
    // beforeDestroy () {},
    // destroyed () {},
    methods: {
      updateIsvAbilityPost(abilityIdList){
return this.$http('post','/op/website/isv/updateIsvAbility',{
  // abilityIdList:this.checkList,
  abilityIdList,
  isvId:this.isvId
},{/* mock: 22 */})
      },
      getAllAbilityGet(){
return this.$http('get','/op/website/isv/getAllAbility',{},{/* mock: 22 */}).then(
  res=>{
     this.abilityList = res
     this.checkListGroup= []
    //  this.abilityFlatList=[]
 
    //  this.transFlat(res)
        this.abilityList.forEach(() => {
       this.checkListGroup.push([])
     })
this.checkList.forEach(itemCheck => {
this.abilityList.forEach((itemChildList,indexChildList) => {
let findAbility = itemChildList.children.find(itemFind=>{
  return itemFind.abilityId === itemCheck
})
if(findAbility){
  // console.log(indexChildList)
  this.checkListGroup[indexChildList].push(itemCheck)
}
});


let findAbility = this.abilityFlatList.find(itemFind=>{
  return itemFind.abilityId = itemCheck
})



if(findAbility){
  this.$set(findAbility,'_checked',true)
}
})









  }
)
      },
      transFlat(list){

if(list && list.length>0){

  list.forEach(element => {
    this.abilityFlatList.push(element)
this.transFlat(element.children)

  })


}else{
  // return
}




// this.abilityFlatList.push()

      },
      clickChangeAbility(){
        this.dialogVisible =true
        
        this.checkList=this.resData.abilityList.map(item=>{
          return item.abilityId
        })
        // this.abilityList=this.resData.abilityList.map(item=>{
        //   return item.abilityId
        // })
this.getAllAbilityGet()
      },
      clickDilaogConfirm(){
        this.dialogVisible = false

let abilityIdList = []
this.checkListGroup.forEach(itemCheckList => {
  abilityIdList.push(...itemCheckList)
})

        this.updateIsvAbilityPost(abilityIdList).then(
  ()=>{
   this.getIsvInfoGet(this.isvId)
  }
)
        
      },
      clickEditName(){
        this.$router.push({name:'MyApplicationEdit',query:{isvId:this.isvId}})
      },
      clickCancle(){
        this.$router.push({name:'MyApplication'})
      },
getIsvInfoGet(isvId){
return this.$http('get','/op/website/isv/getIsvInfo',
{
  isvId
},{
  /* mock: 22 */
}).then(
  res=>{
    this.resData = res
  }
)
},
    } // { [key: string]: Function }
    // template: '', // render // string
    // renderError: '' // (createElement: () => VNode, error: Error) => VNode
  }
  </script>
  <style lang="scss" scoped>
.main {
  font-size: 14px;
}
.main-item {
  background: ffffff;
}
.header-1-1 {
  border-left-width: 2px;
  padding-left: 10px;
  border-color: #333333;
}
.header-1-2 {
  font-size: 14px;
}

.mt-22px {
  margin-top: 22px;
}
.item-title {
  color: #666666;
}
.item-value {
  color: #333333;
}
.line {
  background: #d9d9d9;
}

.check-item + .check-item {
  margin-top: 24px;
}
::v-deep .select-abi-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}

.check-item ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.check-item ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0078ff;
}

.check-item ::v-deep .el-checkbox__input.is-checked,
.check-item .el-checkbox {
  color: rgba(0, 0, 0, 0.85);
}

.check-item ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(0, 0, 0, 0.85);
}

.l-table-select-abi ::v-deep {
  color: rgba(0, 0, 0, 0.65);
  .el-dialog__header {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .el-dialog__body {
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .el-dialog__footer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.checkbox-group +.checkbox-group{
  margin-top: 40px;
}
.item-check:nth-child(n+3){
  margin-top: 20px;
}
</style>
  
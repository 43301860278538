import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
// import router from '@/router/index.js'


const OP_COOKIE_PREFIX = process.env.VUE_APP_OP_COOKIE_PREFIX

Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    accessToken: '',
    username: '',
    OP_COOKIE_PREFIX: process.env.VUE_APP_OP_COOKIE_PREFIX,
    OP_DOMAIN:process.env.VUE_APP_OP_DOMAIN,
    // OP_DOMAIN:'http://localhost:8080',
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.accessToken = token
    },
    SET_USERNAME: (state, name) => {
      state.username = name
    },

    INIT_USER: (state) => {
      let localStorageAccessToken = Cookies.get(`${OP_COOKIE_PREFIX}accessToken`)
      let localStorageUsername = Cookies.get(`${OP_COOKIE_PREFIX}username`)

      state.accessToken = localStorageAccessToken || ''
      state.username = localStorageUsername || ''
    },

    CLEAR_USER: (state) => {
      localStorage.setItem('accessToken', '')
      localStorage.setItem('username', '')
      state.accessToken = ''
      state.username = ''
      Cookies.remove(`${OP_COOKIE_PREFIX}accessToken`, { domain: document.domain.split('.').slice(-2).join('.') })
      Cookies.remove(`${OP_COOKIE_PREFIX}username`, { domain: document.domain.split('.').slice(-2).join('.') })
    },
    REDIRECT_TO_LOGIN: (state) => {
      localStorage.setItem('accessToken', '')
      localStorage.setItem('username', '')
      state.accessToken = ''
      state.username = ''
      Cookies.remove(`${OP_COOKIE_PREFIX}accessToken`, { domain: document.domain.split('.').slice(-2).join('.') })
      Cookies.remove(`${OP_COOKIE_PREFIX}username`, { domain: document.domain.split('.').slice(-2).join('.') })
      // router.push({ name: 'Login', query: { redirect: window.location.href } })
      window.location.href = `${state.OP_DOMAIN}/login/?redirect=${window.location.href}`
    }
  },
  actions: {
  },
  modules: {
  }
})

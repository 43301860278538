import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Layout from '../components/layout/index.vue'
import MyApplication from '../views/MyApplication.vue'
import MyApplicationCreat from '../views/MyApplicationCreat.vue'
import MyApplicationDetail from '../views/MyApplicationDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
path:'/',
redirect:'/my-application',
name:'Layout',
component:Layout,
children:[
  {
    path:'my-application',
    name:'MyApplication',
    component:MyApplication,
    children:[

    ]
      },
      {
        path:'application-creat',
        name:'MyApplicationCreat',
        component:MyApplicationCreat,
      },
      {
        path:'application-detail',
        name:'MyApplicationDetail',
        component:MyApplicationDetail,
      },
      {
        path:'application-edit',
        name:'MyApplicationEdit',
        component:()=>import('../views/MyApplicationEdit.vue'),
      },
      {
        path:'statistics',
        name:'Statistics',
        component:()=>import('../views/Statistics.vue'),
      },
]
  },
  {
    path: '*',
    redirect:'/'
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div class="layout flex flex-col h-screen overflow-auto">
    <div class="header flex-0 h-72px bg-header flex items-center justify-between">
      <div class="logo w-200px flex justify-center items-center">
        <img src="@/assets/logo.png" alt="" width="89px" height="36px">
      </div>
      <div>
          <div class="nav-user relative flex items-center cursor-pointer h-6 mr-20">
              <div class="text-sm text-white">
                {{$store.state.username}}
              </div>
              <div class="ml-2.5">
                <i class="el-icon-arrow-down text-white" ></i>
              </div>
              <div class="nav-user-layout absolute -right-5 top-full flex flex-col justify-center items-center pt-6 cursor-default">
                <div class="nav-user-wrapper flex flex-col items-center bg-white ">
                <a class="nav-user-layout-item" :href="`${$store.state.OP_DOMAIN}/user-edit-profile`" target="_blank" rel="noopener noreferrer" >
                  基本资料
                </a>
                <div class="nav-user-layout-line"></div>
                <a class="nav-user-layout-item"   :href="`${$store.state.OP_DOMAIN}/user-edit-password`" target="_blank" rel="noopener noreferrer">
                  修改密码
                </a>
                <div class="nav-user-layout-line"></div>
                <span class="nav-user-layout-item cursor-pointer" @click='logout'>
                  退出登录
                </span>
                </div>
              </div>
            </div>
      </div>
    </div>
    <div class="body flex-1 flex overflow-auto">
      <div class="nav w-200px bg-nav flex-0 flex-shrink-0 flex flex-col">
        <router-link class="nav-item inline-flex justify-center items-center h-12" :to="{name:'MyApplication'}"   v-slot="{ href,isActive}" >
          <a  :href="href" :class="[isActive && 'router-link-active']">
          <div class="flex justify-center items-center">
            <div class="nav-item-icon">
              <img v-if="isActive" src="@/assets/ying-yong-bai.png" width="15px" height="16px">
              <img  v-else src="@/assets/ying-yong.png" width="15px" height="16px">
              </div>
            <div class="nav-item-text ml-2.5">我的应用</div>
          </div>
          </a>
        </router-link>
        <router-link class="nav-item inline-flex justify-center items-center h-12" :to="{name:'Statistics'}" v-slot="{ href,isActive}" >
          <a  :href="href" :class="[isActive && 'router-link-active']">

          <div class="flex justify-center items-center">
            <div class="nav-item-icon">
              <img v-if="isActive" src="@/assets/tong-ji-bai.png" width="15px" height="16px">
              <img v-else src="@/assets/tong-ji.png" width="15px" height="16px">
              </div>
            <div class="nav-item-text ml-2.5">使用统计</div>
          </div>
          </a>
        </router-link>
      </div>
      <router-view class="main bg-main flex-1 p-5 flex flex-col items-stretch overflow-auto"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  // el: '', // string | Element
  name: '', // string
  // parent: '', // Vue instance
  // functional: false, // boolean
  // delimiters: ['{{', '}}'], // Array<string>
  // comments: false,
  components: {},// Object
  // directives: {}, // Object
  // filters: {}, // Object
  // extends: '', // Object | Function
  // mixins: [], // Array<Object>
  // inheritAttrs: true, // boolean
  // model: '', // { prop?: string, event?: string }
  props: {}, // Array<string> | Object
  data () {
    return {
    }
  },
  computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
  // watch: {}, // { [key: string]: string | Function | Object | Array }
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  activated () {},
  deactivated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    logout(){
      this.$store.commit('CLEAR_USER')
      this.$store.commit('REDIRECT_TO_LOGIN')
    }
  } // { [key: string]: Function }
  // template: '', // render // string
  // renderError: '' // (createElement: () => VNode, error: Error) => VNode
  
}
</script>
<style lang="scss" scoped>
.w-200px{
  width: 200px;
}

.h-72px {
  height: 72px;
}
.bg-header,.bg-nav{
  background-color: #00142A;
}
.main{
  min-width: 1000px;
}
.bg-main{
  background-color:#f5f7fa;
}
.nav-item{
  color: #99A4B1;
}
.router-link-active{
  color: white;
  background: #002A59;
}
.nav-user-layout{
  width: 240px;
  background-color: #00142A;
  z-index: 99999;
  display: none;
    // background-color: #00142A;
}
.nav-user:hover .nav-user-layout{
  display: block;
}
.nav-user-layout-item{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 48px;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
}
.nav-user-layout-item:hover{
  background-color: #f6f6f6;
}
.nav-user-layout-line{
  width: 200px;
  height: 1px;

background: #EEEEEE;
}
</style>
